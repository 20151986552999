
// cleave.js
// https://nosir.github.io/cleave.js/

$(() => {

  if (!Cleave) {
    return;
  }

  $('input.phone-input').each(function() {
    new Cleave(this, {
      phone: true,
      phoneRegionCode: 'fr'
    });
  });

  $('input.date-input').each(function() {
    new Cleave(this, {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y']
    });
  });
  
});