$(() => {

  if ($('#client_id').length === 0 || $('#contact_id').length === 0) {
    return;
  }

  // dynamic load client contacts + address (intra)
  const onChangeClient = (event) => {
    const client_id = $('#client_id').val();
    $.get('/json/clients/' + client_id, (data) => {
      if (!data || !data.client || !data.contacts) {
        return;
      }
      if (event) {
        ['address1', 'address2', 'zipcode', 'city', 'country'].forEach(field => {
          $('#' + field).val(data.client[field]);
        });
      }
      // remove options with a value
      $('#contact_id option').each(function() {
        if ($(this).attr('value')) {
          $(this).remove();
        }
      });
      $('#contact_id').val();
      data.contacts.forEach(contact => {
        $('#contact_id').append($('<option>', { value: contact.id, text: contact.name }));
      });
      if (!event) {
        $('#contact_id').val($('#contact_id').data('value'));
      }
    });
  };
  $(document).on('change', '#client_id', onChangeClient);
  onChangeClient();
});