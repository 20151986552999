
const moment = require('moment');

$(() => {

  if ($('#emission_date').length === 0) {
    return;
  }

  //
  $(document).on('change', '#emission_date, #validity_duration', function () {
    const emission_date = $('#emission_date').val();
    const validity_duration = $('#validity_duration').val();

    if (!emission_date || !validity_duration) {
      $('#end_date').val('');
      return;
    }
    const date = moment(emission_date, 'DD/MM/YYYY').add(validity_duration, 'years').subtract(1, 'day').format('DD/MM/YYYY');
    $('#end_date').val(date);
    $('#end_date').trigger('change');
  });

  //
  $(document).on('change', '#end_date', function () {
    if (!$('#end_date').val()) {
      return;
    }
    const emission_date = $('#emission_date').val();
    const end_date = $('#end_date').val();

    const duration = moment(end_date, 'DD/MM/YYYY').diff(moment(emission_date, 'DD/MM/YYYY').subtract(1, 'day'), 'years', true);
    $('#validity_duration').val(Math.floor(duration));
  });
});
