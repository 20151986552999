

$(() => {


  if (!$('.pennylane-debt').length) {
    return;
  }

  $('.pennylane-debt .spinner').html('<img src="/spinner.gif" width="20" />');
  let url = $('.pennylane-debt').data('url');
  $.get(url, (html) => {
    $('.pennylane-debt').html(html);
  });

}); 