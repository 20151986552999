
$(() => {
  if ($('#repeater-criterias').length < 1) {
    return ;
  }

  const criterias_repeater = $('#repeater-criterias').repeater({
    initEmpty: true,
    show: function () {
      $(this).slideDown();
    },
    hide: function (deleteElement) {
      $(this).slideUp(deleteElement);
    },
    isFirstItemUndeletable: true
  });

  if (typeof criterias !== 'undefined') {
    criterias_repeater.setList(criterias);
  }
});


