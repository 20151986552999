

$(() => {

  if (!$('#select_categories').length) {
    return;
  }

  $('#select_categories').on('change', function() {
    const checked = $(this).is(':checked');
    $('.toggle-select-categories').toggle(checked);
  });
  $('#select_categories').trigger('change');

});