
$(() => {
  if ($('#repeater-questions').length < 1) {
    return ;
  }

  questions = questions || [];

  const ABC = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I' ];
  const onShowQuestion = (question) => {
    // toggle answer type
    question.on('change', 'input.btn-check', function() {
      const answer = $(this).closest('.answer');
      const value = answer.find('input.btn-check:checked').val();
      answer.find('.type').hide();
      answer.find(`.type-${value}`).show();
    });
    // only one answer can be correct
    question.on('change', 'input.form-check-input', function() {
      question.find('input.form-check-input').not(this).prop('checked', false);
    });

    refreshQuestion(question);
  };

  const refreshQuestion = (question) => {
    let i = 0;
    question.find('.answer').each(function() {
      const answer = $(this);
      answer.find('.letter').html(ABC[i++]);
      const value = answer.find('input.btn-check:checked').val();
      if (!value) {
        answer.find('input.btn-check[value="text"]').prop('checked', true);
      }
    });
  };

  const setRadiosIds = () => {
    // radio buttons
    let i = 0;
    $('.question').each(function() {
      let j = 0;
      const question = $(this);
      question.find('input.btn-check').each(function() {
        $(this).prop('id', `inputradio_${i}_${j++}`);
      });
      j = 0;
      question.find('label.btn-sm').each(function() {
        $(this).prop('for', `inputradio_${i}_${j++}`);
      });
      i++;
    });
  };

  const questions_repeater = $('#repeater-questions').repeater({
    initEmpty: true,
    isFirstItemUndeletable: false,
    show: function () {
      $(this).slideDown();
      onShowQuestion($(this));
      setRadiosIds();
    },
    hide: function (deleteElement) {
      $(this).slideUp(deleteElement);
    },
    repeaters: [{
      selector: '.answers-repeater',
      show: function () {
        $(this).show();
        refreshQuestion($(this).closest('.question'));
        setRadiosIds();
      },
      hide: function (deleteElement) {
        const question = $(this).closest('.question');
        $(this).hide(deleteElement);
        setTimeout(() => {
          refreshQuestion(question);
        }, 500);
      },
    }]
  });

  questions_repeater.setList(questions);

  // set images
  questions.forEach((question, index) => {
    const item = $('#repeater-questions').find('.question[data-repeater-item]').eq(index);
    if (question.image) {
      const src = `/documents/${question.image.uuid}/${question.image.name}/`;
      item.find('img.question-image').attr('src', src);
      item.find('img.question-image').removeClass('d-none');
    }

    question.answers.forEach((answer, index) => {
      if (!answer.image) {
        return;
      }
      const a_item = item.find('.answer[data-repeater-item]').eq(index);
      const src = `/documents/${answer.image.uuid}/${answer.image.name}`;
      a_item.find('.btn-check[value=image]').trigger('click');
      a_item.find('img.answer-image').attr('src', src);
      a_item.find('img.answer-image').removeClass('d-none');
    });
  });
});


