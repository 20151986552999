
$(() => {

  if (!$('.form-modules').length) {
    return;
  }

  const modulesOptions = $('select[name=tst_modules_ids] option').clone();

  const onChangeProduct= () => {
    //
    let tst_modules_ids = $('select[name=product_id] option:selected').data('tst_modules_ids');
    tst_modules_ids = tst_modules_ids ? String(tst_modules_ids).split(',') : [];

    //
    $('select[name=tst_modules_ids] option').remove();
    modulesOptions.each(function() {
      if (tst_modules_ids.length === 0 || tst_modules_ids.indexOf($(this).val()) >= 0) {
        $('select[name=tst_modules_ids]').append($(this));
      }
    });
  };

  //
  $('select[name=product_id]').on('change', onChangeProduct);

});