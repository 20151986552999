
$(() => {
  if (!$('.date-repeater').length) {
    return;
  }

  //
  const $dateRepeater = $('.date-repeater');
  const $addButton    = $dateRepeater.find('.add-button');
  const $inputGroup   = $dateRepeater.find('.input-group').clone();

  // Add date input
  const addInput = () => {
    const $clone = $inputGroup.clone();
    $clone.append('<button class="btn btn-sm btn-outline-danger remove-button" type="button"><i class="fal fa-trash"></i></button>');
    $('.date-repeater .input-group').last().after($clone);
    window.initDatepicker();
    return $clone;
  };

  $addButton.on('click', function() {
    if ($('.date-repeater .input-group').length >= 5) {
      return ;
    }

    addInput();
    if ($('.date-repeater .input-group').length >= 5) {
      $addButton.hide();
    }
  });

  // Remove date input
  $('body').on('click', '.remove-button', function(e) {
    e.preventDefault();
    $(this).parents('.input-group').remove();
    $addButton.show();
  });

  // Set values
  let values = $dateRepeater.data('values');
  if (!values) {
    return ;
  }
  values = values.split(',');
  values.forEach((value, index) => {
    if (index === 0) {
      $dateRepeater.find('.input-group input').val(value);
      return ;
    }
    const $clone = addInput();
    $clone.find('input').val(value);
  });
});
