
$(() => {
  if ($('#filterErrors').length === 0) {
    return;
  }

  $('#filterErrors').on('keyup', function() {
    const value = $(this).val().toLowerCase();
    $('.form-check').filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) >= 0);
    });
  });

  const newErrorModal = document.getElementById('newErrorModal');
  newErrorModal.addEventListener('hidden.bs.modal', () => {
    $('#newError').val('');
  });
});
