import { filterSelect } from '../utils/filter';

$(() => {

  if ($('form.client-financer-form').length) {
    filterSelect('#financer_id', '#contact_id', 'financer_id');
  }

  $('form').on('change', '#is_specific_discount', (e) => {
    if (e.target.checked) {
      $('.specific_discount_document_toggle').show();
    } else {
      $('.specific_discount_document_toggle').hide();
    }
  });

  $('#is_specific_discount').trigger('change');

});