const toNumber = (...args) => {
  const v = args.map(v => Number(v) > 0 || v === '0' ? Number(v) : null).filter(v => v !== null)[0];
  return v ?? null; 
};

const getDiscount = (price, discount, discounted_price) => {
  if (discount) {
    return discount;
  }

  if (discount === 0 && discounted_price === null) return 0;
  if (discounted_price === 0 && discount === null) return 100;

  if (discounted_price) {
    return (1 - discounted_price / price) * 100;
  }

  return null;
};

const actualDiscount = toNumber($('#discount').val() || $('#waiting_discount').val());
const actualDiscountedPrice = toNumber($('#discounted_price').val() || $('#waiting_discounted_price').val());

export const onChangeDiscount = () => {
  const hasRight = $('#waiting_discount_warnings').data('has_discount_right');
  if (hasRight) {
    $('#waiting_discount_warnings').hide();
    return;
  }
  let price = toNumber($('#product_id option:selected').data('price'), $('#extra_product_id option:selected').data('price'));
  if ($('#quote-product-form').length) {
    // quote product form : use the selected product price
    const type = $('input[name=type]:checked').val();
    if (type === 'inter') {
      price = toNumber($('#product_id option:selected').data('inter_price'));
    } else if (type === 'intra') {
      price = toNumber($('#product_id option:selected').data('intra_price'));
    } else if (type === 'inter_option') {
      price = toNumber($('#option_id option:selected').data('inter_price'));
    } else if (type === 'intra_option') {
      price = toNumber($('#option_id option:selected').data('intra_price'));
    } else if (type === 'extra') {
      price = toNumber($('#extra_product_id option:selected').data('price'));
    }
  }
  
  const discount = toNumber($('#discount').val(), $('#waiting_discount').val());
  const discountedPrice = toNumber($('#discounted_price').val(), $('#waiting_discounted_price').val());
    
  const discountedPricePercent = getDiscount(price, discount, discountedPrice);
  const isNbDiscountPlace = Number($('#waiting_discount_warnings').data('nb_place_discount')) > 0;
  const sessionDiscount = toNumber($('#waiting_discount_warnings').data('session_discount'));
  const isSpecificDiscount = $('#waiting_discount_warnings').data('is_specific_discount');

  const canDiscountWithoutRight = () => {
    if (isNbDiscountPlace && sessionDiscount === discountedPricePercent) return true;
    if ((actualDiscount || actualDiscount === 0) && discount === actualDiscount && (actualDiscount !== sessionDiscount || isNbDiscountPlace)) return true;
    if ((actualDiscountedPrice || actualDiscountedPrice === 0) && discountedPrice === actualDiscountedPrice) return true;
    if (isSpecificDiscount) return true;
    if (discountedPricePercent !== null && discountedPricePercent <= 5) return true;
    if (!discount && !discountedPrice && discountedPrice !== 0) return true;

    return false;
  };

  $('#waiting_discount_warnings').toggle(!canDiscountWithoutRight());
};

$(() => {  
  // for discounts
  const discountInput = $('input[name=discount]');
  const discountedPriceInput = $('input[name=discounted_price]');

  const waitingDiscountInput = $('input[name=waiting_discount]');
  const waitingDiscountedPriceInput = $('input[name=waiting_discounted_price]');

  const canDisable = ((discountInput.length || discountedPriceInput.length) 
    && !(discountInput.attr('disabled') === 'disabled' && discountedPriceInput.attr('disabled') === 'disabled')) 
    || ((waitingDiscountInput.length || waitingDiscountedPriceInput.length) 
    && !(waitingDiscountInput.attr('disabled') === 'disabled' && waitingDiscountedPriceInput.attr('disabled') === 'disabled'));

  if (canDisable) {
    const disableDiscounts = () => {
      const discountToDisable = (discountInput.length && discountInput) || (waitingDiscountInput.length && waitingDiscountInput);
      const discountedPriceToDisable = (discountedPriceInput.length && discountedPriceInput) || (waitingDiscountedPriceInput.length && waitingDiscountedPriceInput);

      const discount          = discountToDisable.val() !== '' && Number(discountToDisable.val()) >= 0;
      const discounted_price  = discountedPriceToDisable.val() !== '' && Number(discountedPriceToDisable.val()) >= 0;

      discountToDisable.attr('disabled', discounted_price);
      discountedPriceToDisable.attr('disabled', discount);
    };

    $(document).on('keyup change blur', 'input[name=discount]', disableDiscounts);
    $(document).on('keyup change blur', 'input[name=discounted_price]', disableDiscounts);
    $(document).on('keyup change blur', 'input[name=waiting_discount]', disableDiscounts);
    $(document).on('keyup change blur', 'input[name=waiting_discounted_price]', disableDiscounts);

    disableDiscounts();
  }

  $('#discount').on('keyup change blur', onChangeDiscount);
  $('#discounted_price').on('keyup change blur', onChangeDiscount);
  $('#waiting_discount').on('keyup change blur', onChangeDiscount);
  $('#waiting_discounted_price').on('keyup change blur', onChangeDiscount);
  $('#product_id').on('change', onChangeDiscount).trigger('change');
  $('#extra_product_id').on('change', onChangeDiscount).trigger('change');
});