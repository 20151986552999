
$(() => {

  if (!$('.form-indices').length) {
    return;
  }

  const indicesOptions = $('select[name=indices_ids] option').clone();

  const onChangeProduct= () => {
    //
    let indices_ids = $('select[name=product_id] option:selected').data('indices_ids');
    indices_ids = indices_ids ? String(indices_ids).split(',') : [];

    //
    $('select[name=indices_ids] option').remove();
    indicesOptions.each(function() {
      if (indices_ids.length === 0 || indices_ids.indexOf($(this).val()) >= 0) {
        $('select[name=indices_ids]').append($(this));
      }
    });
  };

  //
  $('select[name=product_id]').on('change', onChangeProduct);

});