
$(() => {
  // nav-toggle
  $(document).on('click', '.nav-toggle a.nav-link', function(e) {
    e.preventDefault();
    const el      = $(this);
    const parent  = el.closest('.nav-toggle').parent();
    if (el.hasClass('active')) {
      return;
    }
    parent.find('.nav-toggle a.nav-link').removeClass('active');
    el.addClass('active');

    parent.find('.nav-content > div').each(function() {
      const div = $(this);
      const id = '#' + div.attr('id');
      if (id === el.attr('href')) {
        div.show();
      } else {
        div.hide();
      }
    });
    
    return false;
  });
});
