$(() => {

  if ($('#customregistration-form').length === 0) {
    return;
  }

  // repeater : certificate
  if ($('#repeater-certificate').length) {
    const certificate_repeater = $('#repeater-certificate').repeater({
      // initEmpty: true,
      isFirstItemUndeletable: false,
      show: function () {
        $(this).show();
        window.initDatepicker();
      }
    });
    certificate_repeater.setList(window.certificates);
  
    window.certificates.forEach((certificate, index) => {
      const item = $('#repeater-certificate').find('[data-repeater-item]').eq(index);
      if (certificate.document) {
        item.find('.without-document').hide();
        
        item.find('.with-document input').val(certificate.document.name);
        item.find('.with-document .btn').on('click', function(e) {
          e.preventDefault();
          item.find('.without-document').show();
          item.find('.with-document').hide();
        });
        item.find('.with-document').show();
      }
  
    });
  }  

  const training_amount = $('input[name=training_amount]');
  const action_amount = $('input[name=action_amount]');
  
  // copy training_amount to action_amount
  if (!action_amount.val()) {
    training_amount.on('change, keyup', function() {
      action_amount.val($(this).val());
    });
  }
});
