

$(() => {
  if (!$('.client-add-trainee').length) {
    return;
  }

  //
  $('.client-add-trainee').on('click', function(e) {
    e.preventDefault();
    var $form = $(this).parents('form');

    $('<input>').attr({
      type: 'hidden',
      name: 'submit_type',
      value: 'add_trainee'
    }).appendTo($form);

    $form.trigger('submit');
  });

  //
  const setAnonymousRows = () => {
    const nb_total = Number($('input[name="nb_total"]').val());
    $('.anonymous-row').remove();
    if (!nb_total) {
      return;
    }
    const nb = $('.trainees-rows').find('tr').length;
    for (let i = 0; i < (nb_total - nb); i++) {
      $('.trainees-rows').append('<tr class="anonymous-row"><td colspan="3" class=""><em>Stagiaire à définir</em></td></tr>');
    }
  };
  $('input[name="nb_total"]').on('change', setAnonymousRows);
  $('input[name="nb_total"]').trigger('change');

  // Remove submit on enter press
  $('input[name="nb_total"]').on('keydown', function(e) {
    if (e.key === 'Enter'){
      e.preventDefault();
      $(this).trigger('blur');
    }
  });
});
