
$(() => {

  if (!$('.form-categories').length) {
    return;
  }

  const categoriesOptions = $('select[name="categories_ids[]"]:first option').clone();
  const optionsOptions    = $('select[name="sel_options_products[]"]:first option').clone();
  const categoryRow       = $('.form-categories .category').first().clone();

  // products and categories
  const onChangeProduct = () => {
    const training_type_id = Number($('#training_type_id').val());
    const option      = $('select#product_id option:checked');

    if (!option.data('categories')) {
      // no category
      $('.form-categories .category').remove();
      $('.form-categories').hide();
      return;
    }

    $('.form-categories').show();

    const categories  = String(option.data('categories')).split(',') || [];
    const nb          = option.data('nb');

    // remove categories lines if too many
    while ($('.form-categories .category').length > nb) {
      $('.form-categories .category').last().remove();
    }
    
    // add categories lines if not enough
    if (categoryRow.length > 0) {
      while ($('.form-categories .category').length < nb) {
        const row = categoryRow.clone();
        row.find('select[name="categories_ids[]"]').val('');
        row.find('select[name="levels[]"]').val('');
        row.find('input[name="options[]"]').val('');
        row.find('input[name="options_products[]"]').val('');
        $('.form-categories').append(row);
      }
    }

    // filter categories
    $('select[name="categories_ids[]"]').each(function() {
      const select = $(this);
      // select.find('option').remove();
      categoriesOptions.each(function() {
        const newOption = $(this).clone();
        const value = newOption.attr('value');
        const present = select.find(`option[value="${value}"]`).length > 0;
        if (!present && (categories.length === 0 || categories.indexOf(value) > -1)) {
          // add if missing
          select.append(newOption);
        } else if (value && present && categories.indexOf(value) < 0) {
          // remove if present
          select.find(`option[value="${value}"]`).remove();
        }
      });
    });

    // filter options products
    $('select[name="sel_options_products[]"]').each(function() {
      if (!training_type_id) {
        return;
      }
      const select = $(this);
      // select.find('option').remove();
      optionsOptions.each(function() {
        const newOption = $(this).clone();
        const value = newOption.attr('value');
        const present = select.find(`option[value="${value}"]`).length > 0;
        if (!present && newOption.data('training_type_id') === training_type_id) {
          // add if missing
          select.append(newOption);
        } else if (present && newOption.data('training_type_id') !== training_type_id) {
          // remove if present
          select.find(`option[value="${value}"]`).remove();
        }
      });
    });

    // re-enable select2
    window.initSelect2();

  };
  $(document).on('change', 'select#product_id', onChangeProduct);
  onChangeProduct();
  
  // initialize selected categories

  // sync hidden input for option
  const syncSelectMultiple = () => {
    $('.form-categories select.select2-multiple').each(function() {
      const values = $(this).find(':selected').map((i, option) => $(option).val()).get();
      $(this).parent().find('input[type=hidden]').val(values.join(','));
    });
  };
  $(document).on('change', '.form-categories select.select2-multiple', syncSelectMultiple);

  const initSelectMultiple = () => {
    $('.form-categories select.select2-multiple').each(function() {
      const select = $(this);
      const input = $(this).parent().find('input[type=hidden]');
      select.val(input.val().split(','));
    });
    window.initSelect2();
  };
  initSelectMultiple();

});