import { filterSelect } from '../utils/filter';

const {moment} = window;

$(() => {
  const days = $('#session_day_form').find('input[name=day]').map((i, e) => e.value).get();

  const sessionEditForm = $('#session_edit');
  sessionEditForm.on('submit', (e) => {
    const newDays = $('#session_day_form').find('input[name=day]').map((i, e) => e.value).get();
    if (days.join() !== newDays.join()) {
      if (!window.confirm('Attention, vous allez modifier les dates de la session. Êtes-vous sûr de vouloir continuer ?')) {
        e.preventDefault();
      }
    }
  });


  if (!$('.session-table-form').length) {
    return;
  }

  //
  $('.session-table-form .session-day input[name=day]').first().on('change', function() {
    const value = $(this).val();
    $('#begin_at').val(value);
  });

  //
  const updateColumns = function(change_dates) {
    let begin_at  = $('input[name=begin_at]').val();
    let nb_days   = $('input[name=nb_days]').val();

    // force first date
    if (change_dates) {
      const m = moment(begin_at, 'DD/MM/YYYY');
      $('.session-table-form .session-day input[name=day]').each(function() {
        $(this).val(m.format('DD/MM/YYYY'));
        m.add(1, 'day');
      });
    } else {
      $('.session-table-form .session-day input[name=day]').first().val(begin_at);
    }

    nb_days = Number(nb_days) || 1;
    
    while ($('.session-table-form .session-day').length > nb_days) {
      // remove last column
      $('.session-table-form .session-day').last().remove();
    }

    if ($('.session-table-form .session-day').length > 0) {
      while ($('.session-table-form .session-day').length < nb_days) {
        // add column
        const lastcolumn = $('.session-table-form .session-day').last();
        if (!lastcolumn.length) {
          break;
        }
        const column  = lastcolumn.clone();
        
        // set j+1 for new column
        const day     = lastcolumn.find('.day input').val();
        const m       = moment(day, 'DD/MM/YYYY');
        column.find('.day input').val(m.add(1, 'day').format('DD/MM/YYYY'));
        
        column.insertAfter(lastcolumn);
      }
      // re init datepicker
      window.initDatepicker();
    }
  };
  updateColumns();

  $(document).on('change', 'input[name=begin_at]', () => updateColumns(true));
  $(document).on('change', 'input[name=nb_days]', () => updateColumns());

  //
  // const getCategoriesArray = (parent) => {
  //   const categories = parent.find('input[name="categories[]"]').val();
  //   if (!categories) {
  //     return [];
  //   }
  //   return categories.split(',').map(v => Number(v));
  // };

  // //

  
  // update categories:
  // - badges are created based on the hidden input
  // - dropdown items are filter according to the selected training and the already present categories
  // const updateCategories = function() {

  //   const training_id = Number($('select[name=training_id]').val());
  //   $('.session-categories').each(function() {
  //     const parent = $(this);
  //     const categories = getCategoriesArray(parent);

  //     // remove and add selected categories (in badges)
  //     parent.find('.category').remove();
  //     categories.forEach(category_id => {
  //       const item = parent.find(`.dropdown-item[data-id=${category_id}]`);
  //       const name = item.text();
  //       const badge = $('<div/>', { class: 'btn btn-xs btn-secondary category ms-1' });
  //       badge.data('id', category_id);
  //       badge.text(name);
    
  //       // insert badge at correct position
  //       let inserted = false;
  //       parent.find('.category').each(function() {
  //         if (inserted) {
  //           return;
  //         }
  //         const el = $(this);
  //         if (name.localeCompare(el.text()) < 0) {
  //           badge.insertBefore(el);
  //           inserted = true;
  //         }
  //       });
  //       if (!inserted) {
  //         badge.insertBefore(parent.find('.add-category'));
  //       }
  //     });
    
  //     // update available categories in dropdown
  //     let has_items = false;
  //     parent.find('.dropdown-item').each(function() {
  //       const id = $(this).data('id');
  //       const category_visible = $(this).data('training_id') === training_id && categories.indexOf(id) < 0;
  //       has_items = has_items || category_visible;
  //       // show/hide category in dropdown menu
  //       $(this).toggle(category_visible);
  //     });

  //     // show/hide + button
  //     parent.find('.add-category').toggle(has_items);
  //   });

  // };
  // updateCategories();

  // const changedTraining = () => {
  //   $('.session-table-form .category').remove();
  //   updateCategories();
  // };
  // $(document).on('change', 'select[name=training_id]', changedTraining);

  // // add category
  // const addCategory = function() {
  //   const parent      = $(this).closest('.session-categories');
  //   const categories  = getCategoriesArray(parent);
  //   categories.push($(this).data('id'));
  //   parent.find('input[name="categories[]"]').val(categories.join(','));

  //   updateCategories();
  //   return false;
  // };
  // $(document).on('click', '.session-categories .dropdown-item', addCategory);


  // // remove category
  // const removeCategory = function() {
  //   const parent      = $(this).closest('.session-categories');
  //   const categories  = getCategoriesArray(parent);
  //   const index = categories.indexOf($(this).data('id'));
  //   if (index >= 0) {
  //     categories.splice(index, 1);
  //     parent.find('input[name="categories[]"]').val(categories.join(','));
  //     updateCategories();
  //   }
  // };
  // $(document).on('click', '.category', removeCategory);

  

  // handle categories, indices and modules
  const manageCategories = (div, item, add, input) => {

    const getArray = (parent) => {
      const categories = parent.find(input).val();
      if (!categories) {
        return [];
      }
      return categories.split(',').map(v => Number(v));
    };

    // 
    const update = function() {

      const training_id = Number($('select[name=training_id]').val());
      $(div).each(function() {
        const parent      = $(this);
        const categories  = getArray(parent);

        // remove and add selected categories (in badges)
        parent.find(item).remove();
        categories.forEach(category_id => {
          const $item = parent.find(`.dropdown-item[data-id=${category_id}]`);
          const badge = $('<div/>', { class: `btn btn-xs btn-secondary ${item.substring(1)} ms-1`, 'data-tooltip': $item.text() });
          badge.data('id', category_id);
          const name  = $item.data('name') || $item.text();
          badge.text(name);
      
          // insert badge at correct position
          let inserted = false;
          parent.find(item).each(function() {
            if (inserted) {
              return;
            }
            const el = $(this);
            if (name.localeCompare(el.text()) < 0) {
              badge.insertBefore(el);
              inserted = true;
            }
          });
          if (!inserted) {
            badge.insertBefore(parent.find(add));
          }
        });
      
        // update available categories in dropdown
        let has_items = false;
        parent.find('.dropdown-item').each(function() {
          const id      = $(this).data('id');
          const item_training_id = $(this).data('training_id');
          const visible = categories.indexOf(id) < 0 && (!item_training_id || item_training_id === training_id);
          has_items = has_items || visible;
          // show/hide category in dropdown menu
          $(this).toggle(visible);
        });

        // show/hide + button
        parent.find(add).toggle(has_items);
      });
    };
    update();
    
    const changedTraining = () => {
      $(`.session-table-form ${item}`).remove();
      update();
    };
    $(document).on('change', 'select[name=training_id]', changedTraining);

    // add category
    const addCategory = function() {
      const parent  = $(this).closest(div);
      const categories = getArray(parent);
      categories.push($(this).data('id'));
      parent.find(input).val(categories.join(','));

      update();
      return false;
    };
    $(document).on('click', `${div} .dropdown-item`, addCategory);


    // remove category
    const removeCategory = function() {
      const parent      = $(this).closest(div);
      const categories  = getArray(parent);
      const index       = categories.indexOf($(this).data('id'));
      if (index >= 0) {
        categories.splice(index, 1);
        parent.find(input).val(categories.join(','));
        update();
      }
      $('.tooltip.show').remove();
    };
    $(document).on('click', item, removeCategory);
  };

  manageCategories('.session-categories', '.category', '.add-category', 'input[name="categories[]"]');
  manageCategories('.session-indices', '.indice', '.add-indice', 'input[name="indices[]"]');
  manageCategories('.session-modules', '.module', '.add-module', 'input[name="tst_modules[]"]');


  const hideTests = () => {
    let is_ut_enabled;
    if ($('#ut-enabled').length) {
      is_ut_enabled = $('#ut-enabled').data('value');
    } else {
      const option  = $('select[name=training_type_id] option:selected');
      is_ut_enabled = option.data('is_ut_enabled');
    }
    if (is_ut_enabled) {
      $('.ut-enabled').show();
      $('.cell-100').addClass('cell-66').removeClass('cell-100');
    } else {
      $('.ut-enabled').hide();
      $('.cell-66').addClass('cell-100').removeClass('cell-66');
    }
  };
  $(document).on('change', 'select[name=training_type_id]', hideTests);
  hideTests();

  const duplicate_comment_button = $('#duplicate_comment');
  duplicate_comment_button.on('click', (e) => {
    e.preventDefault();
    $('#ged_details').val($('#description').val());

  });

  const toggleCategoriesAndIndices = () => {
    const option  = $('select[name=training_id] option:selected');
    $('.toggle-categories').toggle(!!option.data('is_categories'));
    $('.toggle-indices').toggle(!!option.data('is_indices'));
    $('.toggle-modules').toggle(!!option.data('is_tst'));
  };
  $(document).on('change', 'select[name=training_id]', toggleCategoriesAndIndices);
  toggleCategoriesAndIndices();

  filterSelect('#training_type_id', '#product_id', 'training_type_id');
  filterSelect('#centre_id', '#centre_address_id', 'centre_id');

  const showCentreWarning = () => {
    const selected    = $('#centre_id option:selected').data('company_id');
    const company_id  = Number($('#company_id').val());
    const warning     = selected && selected !== company_id;
    const exists      = $('#centre-warning').length;
    if (warning && !exists) {
      $('label[for=centre_id]').append('<span id="centre-warning" class="ms-1" ><i class="fal fa-warning text-warning" data-tooltip="Le centre n\'est pas rattaché à la société." ></i></span>');
    } else if (!warning && exists) {
      $('#centre-warning').remove();
    }
  };
  $(document).on('change', '#centre_id, #company_id', showCentreWarning);
  showCentreWarning();
}); 