import { filterItemBySelect } from '../utils/filter';


$(() => {

  if ($('select[name=training_id]').length) {

    // TRAININGS
    const categoriesOptions = $('select[name=categories_ids] option').clone();
    
    const onChangeTraining = () => {

      // toggle ut-enabled
      const selected = $('select[name=training_id] option:selected');
      const is_ut_enabled = selected && selected.data('is_ut_enabled');
      $('.is-ut-enabled').toggle(is_ut_enabled);

      const training_id = Number($('select[name=training_id]').val());

      // update code prefix
      const prefix = $('select[name=training_id] option:selected').data('code');
      $('input[name=code]').closest('.input-group').find('.input-group-text').text(prefix + '-');

      // filter categories
      const value = $('select[name=categories_ids]').data('value');
      const formValues = value ? String(value).split(',') : [];
      $('select[name=categories_ids] option').remove();
      categoriesOptions.each(function() {
        if ($(this).data('training_id') === training_id) {
          $(this).attr('selected', formValues.indexOf($(this).val()) >= 0);
          $('select[name=categories_ids]').append($(this));
        }
      });

      // toggle indices/categories selects
      $('#select-categories').toggle(!!selected.data('is_categories'));
      $('#select-indices').toggle(!!selected.data('is_indices'));
      $('#select-aipr').toggle(!!selected.data('is_aipr'));
      $('#select-modules').toggle(!!selected.data('is_tst'));
    };

    //
    $('select[name=training_id]').on('change', onChangeTraining);
    onChangeTraining();

    filterItemBySelect('#training_id', '#toggle-is_ut_enabled', 'is_categories');
  }
});
