

$(() => {

  if (!$('.instructors-calendar').length) {
    return;
  }

  // 
  $(document).on('click', '.btn-assign-instructor', function() {
    const href  = $(this).data('href');
    const modal = new bootstrap.Modal(document.getElementById('assign-modal'), {});
    modal.show();
    $.get(href, (html) => {
      $('#assign-modal .modal-content').html(html);
    });
    return false;
  });


  // override radio behavior to allow uncheck
  $(document).on('click', '.btn-group > label', function(e) {
    e.preventDefault();
    const id = $(this).attr('for');
    const radio = $('#' + id);
    if (radio.is(':checked')) {
      e.stopImmediatePropagation();
      $(this).removeClass('active');
      radio.prop('checked', false);
    } else {
      radio.prop('checked', true);
    }
  });

}); 