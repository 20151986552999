


$(() => {

  if (!$('#trainee-form').length) {
    return;
  }

  const updateExistingTrainee = () => {
    const option  = $('select[name=trainee_id] option:selected');
    const id         = option.attr('value');
    if (!id) {
      $('#trainee-form').html('');
      return;
    }
    $.get(`/crm/trainees/${id}/_form?`, (html) => {
      $('#trainee-form').html(html);
    });
  };

  $(document).on('change', 'select.existing[name="trainee_id"]', updateExistingTrainee);

});