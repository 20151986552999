

$(() => {

  $(document).on('submit', '#assign-filter-form', function() {
    const form = $(this);
    const day           = $('#assign-modal input[name=day]').val();
    const instructor_id = $('#assign-modal input[name=instructor_id]').val();
    const url = [
      form.attr('action'),
      '?day=', day,
      '&instructor_id=', instructor_id,
      '&', form.serialize()
    ].join('');

    $.get(url, (html) => {
      $('#assign-modal .modal-content').html(html);
    });
    return false;
  });

  $(document).on('click', '#assign-filter-reset', function() {
    const form = $('#assign-filter-form');
    const day           = $('#assign-modal input[name=day]').val();
    const instructor_id = $('#assign-modal input[name=instructor_id]').val();
    const url = [
      form.attr('action'),
      '?day=', day,
      '&instructor_id=', instructor_id,
    ].join('');

    $.get(url, (html) => {
      $('#assign-modal .modal-content').html(html);
    });
  });


  const MANDATORY_COMMENT = ['for', 'au', 're', 'grp'];

  const refreshComment = () => {
    const am = $('input[name=am]:checked').val() || '';
    const pm = $('input[name=pm]:checked').val() || '';
    const required = MANDATORY_COMMENT.includes(am) || MANDATORY_COMMENT.includes(pm);
    
    $('#comment').prop('required', required);
    $('label[for=comment]').toggleClass('required', required);
    if (required) {
    }
  };

  $(document).on('click', 'label.btn', function() {
    refreshComment();
  });
  refreshComment();
});