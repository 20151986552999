
import { filterSelect } from '../utils/filter';

$(() => {

  if ($('#new-quote-form').length) {
    const onChangeClient = () => {
      const data          = $('select#client_id').data('select2') && $('select#client_id').select2('data')[0];
      // select option
      $('#payment_term').val(data.payment_term);
    };
    $(document).on('change', 'select#client_id', onChangeClient);
  }

  if ($('#quote-product-form').length) {

    filterSelect('#training_type_id', '#product_id', 'training_type_id');
    
    const updateOptions = (sel, attr) => {
      $(`${sel} option`).each((i, el) => {
        if (!$(el).val()) {
          return;
        }
        const name  = $(el).data('name');
        const price = $(el).data(attr);
        $(el).text(`${name} - ${price} €HT`);
        $(el).data('price', price);
      });
    };

    const changeType = () => {
      const type = $('input[name=type]:checked').val();
      if (type === 'inter') {
        updateOptions('#product_id', 'inter_price');
      } else if (type === 'intra') {
        updateOptions('#product_id', 'intra_price');
      } else if (type === 'inter_option') {
        updateOptions('#option_id', 'inter_price');
      } else if (type === 'intra_option') {
        updateOptions('#option_id', 'intra_price');
      }
            
      $('select.select2').select2('destroy');
      window.initSelect2();
    };

    // update product price in label
    $('input[name=type]').on('change', changeType);
    changeType();

  }


});