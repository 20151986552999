
$(function() {

  //
  $('input[name="daterange"]').each(function() {
    $(this).daterangepicker({
      minDate:    $(this).hasClass('min-today') ? new Date() : undefined,
      autoUpdateInput: false,
      locale: {
        format:     'DD/MM/YYYY',
        separator:  ' - ',
        applyLabel: 'Valider',
        cancelLabel: 'Annuler',
        fromLabel: 'De',
        toLabel: 'A',
        customRangeLabel: 'Custom',
        weekLabel: 'W',
        daysOfWeek: [
          'Di',
          'Lu',
          'Ma',
          'Me',
          'Je',
          'Ve',
          'Sa'
        ],
        'monthNames': [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Decembre'
        ],
        'firstDay': 1
      },
    });
  });
  

  $('input[name="daterange"]').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    const form = $(this).closest('form');
    if (form.hasClass('auto-submit')) {
      form.trigger('submit');
    }
  });

  $('input[name="daterange"]').on('cancel.daterangepicker', function() {
    $(this).val('');
  });

});
