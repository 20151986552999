
$(() => {
  if (!$('.add-trainee-to-request').length) {
    return;
  }

  $('.add-trainee-to-request').on('click', function(e) {
    const trainee_type = $('input[name="trainee_type"]:checked').val();
    const last_name = $('input[name="last_name"]').val();
    if (trainee_type !== 'new' || !last_name) {
      return;
    }

    e.preventDefault();

    const $form = $(this).closest('form');
    const url = $form.attr('action');

    $.ajax({
      url: url + '/name',
      method: 'POST',
      data: JSON.stringify({ last_name }),
      contentType: 'application/json',
      success: (res) => {
        if (!res.trainee || confirm('Attention, un stagiaire du même nom existe déjà dans la base, souhaitez vous continuer ?')) {
          $form.trigger('submit');
          return ;
        }
      },
    });

  });
});
