import { onChangeDiscount } from './discounts';

$(() => {

  if (!$('.registration-form').length || !$('select#client_id').length) {
    return;
  }

  const statuses = $('select[name=status] option').clone();
  // 
  const onChangeClient = () => {
    const client_id     = $('select#client_id').val();
    const selected      = $('select#client_id option:selected');
    const data          = $('select#client_id').data('select2') && $('select#client_id').select2('data')[0];
    const isParticulier = data?.isParticulier || selected.data('isparticulier');

    $('select#trainee_id option').each(function() {
      $(this).attr('value') && $(this).remove();
    });
    // load trainees
    $.get('/planner/json/trainees?client_id=' + client_id, (data) => {
      // $('select#trainee_id option').remove();
      data.trainees && data.trainees.forEach(trainee => {
        $('select#trainee_id').append(`<option value="${trainee.id}">${trainee.name}</option>`);
      });
      $('select#trainee_id').val($('select#trainee_id').data('value'));
    });

    // hide anonym radio for particulier
    $('#trainee-radio-anonym').toggle(!isParticulier);
    $('label[for=trainee-radio-anonym]').toggle(!isParticulier);

    // update registration trainee status select
    statuses.each(function() {
      const mustBePresent = (isParticulier && $(this).data('type') === 'P') || (!isParticulier && $(this).data('type') === 'S');
      const val = $(this).attr('value');
      if (!val) {
        return;
      }
      const option  = $(`select[name=status] option[value=${val}]`);
      const present = option.length > 0;
      if (mustBePresent && !present) {
        $('select[name=status]').append($(this));
      } else if (!mustBePresent && present) {
        option.remove();
      }
    });

    // toggle intra_amount and registration-id
    const main_client_id = $('#main-client').data('client_id');
    $('#main-client').toggle(String(main_client_id) !== client_id);

    if ($('#existing_registrations').length) {
      // set intra_amount
      $('#main-client input[name=intra_amount]').val('');
      $('#registration-id').hide();
      $('#registration-id option').remove();
      let option;
      $('#existing_registrations span').each(function() {
        const span = $(this);
        if (String(span.data('client_id')) === client_id) {
          $('#main-client input[name=intra_amount]').val(span.data('intra_amount') || '');
          $('#registration-id').show();
          option = new Option(span.data('registration_label'), span.data('registration_id'));
          $('#registration-id select').append(option);
        }
      });
      if (option) {
        $('#registration-id select').append(new Option('Créer une nouvelle inscription', ''));
      }
    }

    const specific_discount_document = $('#specific_discount_document');
    const specific_discount_document_link = specific_discount_document.children('a');
    
    const waiting_discount_warnings = $('#waiting_discount_warnings');

    $.get('/planner/json/discount?client_id=' + client_id, (data) => {
      const { is_specific_discount, specific_discount } = data;
      waiting_discount_warnings.data('is_specific_discount', is_specific_discount);
      onChangeDiscount();

      if (!is_specific_discount || !specific_discount) {
        specific_discount_document.hide();
      } else {
        specific_discount_document.show();
        specific_discount_document_link.attr('href', '/documents/'+ specific_discount.uuid + '/' + specific_discount.name);
      }
    });


  };
  $(document).on('change', 'select#client_id', onChangeClient);
  onChangeClient();

  const onChangeCancelFee = () => {
    const cancel_fee = $('select#cancel_fee').val();
    const invoiceTotal = $('#invoice-total').data('price');
    const value =  invoiceTotal * ((cancel_fee / 100 ));
    $('#invoice-total').html(`${value.toFixed(2)}&nbsp;€HT`);
  };
  $(document).on('change', 'select#cancel_fee', onChangeCancelFee);
  onChangeCancelFee();

}); 