$(() => {
  const select = $('#ep_trainee_id');
  const allButton = $('#ep_all_trainees');
  const noneButton = $('#ep_no_trainee');

  const trainees = select.find('option').map((i, e) => e.value).get();

  noneButton.on('click', () => {
    select.val([]).trigger('change');
  });

  allButton.on('click', () => {
    select.val(trainees).trigger('change');
  });
});