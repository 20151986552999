

$(() => {


  if (!$('.revenue-refresh').length) {
    return;
  }

  const refresh = () => {
    $('.revenue-refresh .spinner').html('<img src="/spinner.gif" width="20" />');
    let url = window.location.href;
    if (url.indexOf('?') > -1) {
      url += '&ajax=true';
    } else {
      url += '?ajax=true';
    }
    $.get(url, (html) => {
      $('.revenue-refresh').html(html);
    });
  };

  window.setInterval(refresh, 30000); // 30s
  refresh();

}); 