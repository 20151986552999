

//
export const filterSelect = (parent, child, attr) => {

  if (!$(parent).length) {
    return;
  }

  // save options
  const options = $(`${child} option`).clone();

  const onChange = () => {
    const v = Number($(parent).val());
    $(`${child} option`).remove();

    options.each(function() {
      const option = $(this).clone();
      if (!option.val() || option.data(attr) === v) {
        $(child).append(option);
      }
    });
    // $(child).trigger('change');
  };

  $(document).on('change', parent, onChange);
  onChange();
};


export const filterItemBySelect = (parent, child, value) => {
  if (!$(parent).length) {
    return;
  }

  // save options
  const parentItem = $(`${parent}`);
  const childItem = $(child);


  const onChange = () => {
    const parentItemOption = parentItem.find(':selected');

    const parent_data = parentItemOption.data(value);
    const child_data = childItem.data(value);
    
    if (child_data === parent_data) {
      return childItem.show();
    }
    childItem.hide();
  };

  $(document).on('change', parentItem, onChange);
  onChange();
};

$(() => {
  $('form.auto-submit input, form.auto-submit select').on('change', function() {
    $(this).closest('form').trigger('submit');
  });

  // sort
  $('.table-sort-asc, .table-sort-desc').on('click', function() {
    const sort  = $(this).data('sort');
    const input = $('input[name=sort]');
    input.val(sort);
    input.closest('form').trigger('submit');
  });

});
